
import { defineComponent, ref, computed, reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
import { setSlideUI } from '@/helpers/util/slideUI'
import AppTag from '@/components/atoms/AppTag.vue'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'
import PanelRelatedItem from './PanelRelatedItem.vue'
import TextLinkWithArrow from '@/components/molecules/TextLinkWithArrow.vue'
import makeUpdatedPidString from '@/domain/item/itemViewer/makeUpdatedPidString'
import { createVolumeLabel } from '@/domain/item/ItemListView/relatedItemsLabel'
import { issuedArrayProcessing } from '@/domain/item/ItemListView/contentLabel'

export default defineComponent({
  components: {
    AppTag,
    ButtonIcon,
    PanelRelatedItem,
    TextLinkWithArrow,
  },
  props: {},
  setup () {
    const BASE_URL = process.env.VUE_APP_CONTENTS_BASE_URL

    const store = useStore()

    const scrollTarget = ref()
    const nextButton = ref()
    const prevButton = ref()

    const initSlideUI = (isUpdate = false) => {
      if (!scrollTarget.value || !nextButton.value || !prevButton.value) return

      setSlideUI(
        scrollTarget.value, // scrollTarget: HTMLElement,
        nextButton.value.$el, // nextButton: HTMLElement,
        prevButton.value.$el, // prevButton: HTMLElement,
        reactive({ currentPosition: 1 }),
        16,
        true, // touchSupport = true,
        isUpdate // update = false
      )
    }

    const relatedItemList = computed(() => store.getters.relatedItemList)

    onMounted(() => { initSlideUI(false) })

    const isShowPanel = computed(() => {
      const list = store.getters.relatedItemList || []
      const hasItem = list.findIndex((item: any) => item.list ? (item.list.length !== 0) : false)
      return hasItem >= 0
    })

    const placeholderImageType = computed(() => {
      return store.getters.PlaceholderImageType
    })

    const hasPlaceholderImageType = (item: any) => {
      if (!item || !item.collections) return ''
      return placeholderImageType.value[item.collections[0]]
    }

    const metaArrayProcessing = (meta: any, key: string) => {
      if (meta) {
        return meta[key] ? meta[key].join('') : ''
      }
      return ''
    }

    const metaAuthorAndPublication = (meta: any, key: string) => {
      // 著者
      const author = meta && meta[key] ? meta[key].join(', ') : ''
      // 出版年
      const issued = meta ? issuedArrayProcessing(meta, true) : ''
      const publication = issued ? '(' + issued + ')' : ''
      // 区切り文字
      const sep = author && publication ? ' ' : ''
      // 結合
      return author + sep + publication
    }

    return {
      BASE_URL,
      scrollTarget,
      nextButton,
      prevButton,
      relatedItemList,
      placeholderImageType,
      metaArrayProcessing,
      hasPlaceholderImageType,
      isShowPanel,
      makeUpdatedPidString,
      createVolumeLabel,
      metaAuthorAndPublication,
    }
  },
})
